import React, { ReactElement } from "react";
import PhoneIcon from "@components/autowarranty/icons/PhoneIcon";
import LocationIcon from "@components/autowarranty/icons/LocationIcon";
import EmailIcon from "@components/autowarranty/icons/EmailIcon";
interface IContactUsList {
    icon: ReactElement;
    text: string;
}

export const contactUs: IContactUsList[] = [
    {
        icon: <EmailIcon />,
        text: "info@AutoWarranty.com",
    },
    {
        icon: <PhoneIcon />,
        text: "+1 (929) 387-3001",
    },
    {
        icon: <LocationIcon />,
        text: "2810 N Church St #56440 Wilmington, DE 19802",
    },
];

const ContactUs = () => {
    return (
        <div className="flex flex-col gap-2 items-start">
            <div className="text-base lg:text-lg text-[#93CDE9] font-bold">
                Contact Us
            </div>
            {contactUs.map((item) => (
                <div
                    key={item.text}
                    className="flex items-start justify-start gap-2 text-xs lg:text-base font-light text-left"
                >
                    <span>{item.icon}</span>
                    <span>{item.text}</span>
                </div>
            ))}
        </div>
    );
};

export default ContactUs;
