import { links } from "@components/autowarranty/layout/header/Links";
import React from "react";

const Navigation = () => {
    return (
        <div className="flex flex-col gap-2 items-start">
            <div className="text-base lg:text-lg text-[#93CDE9] font-bold">
                Company
            </div>
            {links
                .filter((item) => item.showOnFooter)
                .map((item) => (
                    <div
                        key={item.footerTitle}
                        className="flex items-center gap-4 text-xs lg:text-base text-left font-light"
                    >
                        <a href={item.url}>{item.footerTitle}</a>
                    </div>
                ))}
        </div>
    );
};

export default Navigation;
