import React from "react";
import FooterLogo from "./components/FooterLogo";
import ContactUs from "./components/ContactUs";
import Navigation from "./components/Navigation";

const FirstSection = () => {
    return (
        <div className="flex flex-col lg:flex-row items-start justify-between gap-[2rem] lg:gap-[10rem]">
            <FooterLogo />
            <div className="flex flex-col md:flex-row items-start justify-between gap-[1rem] lg:gap-[10rem]">
                <ContactUs />
                <Navigation />
            </div>
        </div>
    );
};

export default FirstSection;
