import React from "react";
import FirstSection from "./sections/FirstSection";
import SecondSection from "./sections/SecondSection";

const Footer = () => {
    return (
        <footer className="px-4 bg-[#19378C] text-white mt-20">
            <div className="max-w-7xl mx-auto">
                <div className="flex flex-col gap-[5rem] lg:mx-auto pt-32 pb-6">
                    <FirstSection />
                    <SecondSection />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
