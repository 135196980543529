import React from "react";
// import { useDispatch } from "react-redux";
// import { setModal } from "@redux/shared/slices/modal";

const yearNow = new Date();

const ThirdSection = () => {
    // const dispatch = useDispatch();
    // const footerMenu = [
    //     {
    //         title: "Terms & Conditions",
    //         onClick: () =>
    //             dispatch(setModal({ active: true, content: "terms" })),
    //     },
    //     {
    //         title: "Privacy Policy",
    //         onClick: () =>
    //             dispatch(setModal({ active: true, content: "privacy" })),
    //     },
    // ];
    return (
        <div className="flex flex-col lg:flex-row items-start lg:items-center gap-6 justify-between font-light text-xs text-left border-t-.5 border-t-gray-300 pt-4">
            <div className="font-light text-sm">
                <span>
                    <span>©</span> <span>{yearNow.getFullYear()}</span>{" "}
                    <a href={`https://autoWarranty.com`}>AutoWarranty.com</a>
                </span>
            </div>
            {/* <div>
                <ul className="flex flex-wrap items-center text-left gap-2 lg:gap-6">
                    {footerMenu.map((item, index) => (
                        <li key={index}>
                            <a
                                className=" cursor-pointer"
                                onClick={item.onClick}
                            >
                                <span>{item.title}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div> */}
        </div>
    );
};

export default ThirdSection;
