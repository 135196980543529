import AutoWarrantyLightLogo from "@components/autowarranty/logo/AutoWarrantyLightLogo";
import React from "react";

const FooterLogo = () => {
    return (
        <div
            className="logo max-w-200px lg:max-w-300px"
            style={{
                width: "auto",
                height: "auto",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <a href="/" aria-label={`autoWarranty logo`}>
                {" "}
                <span className="children:w-full children:h-auto lg:children:h-10">
                    <AutoWarrantyLightLogo />
                </span>
            </a>
        </div>
    );
};

export default FooterLogo;
