import React from "react";

function LocationIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <mask
                id="mask0_101_451"
                style={{ maskType: "alpha" }}
                width="25"
                height="25"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H25V25H0z"></path>
            </mask>
            <g mask="url(#mask0_101_451)">
                <path
                    fill="#93CDE9"
                    fillRule="evenodd"
                    d="M11.796 22.817C9.53 20.376 4 14.53 4 9.825 4 2.88 12.864-.618 18.028 4.295c1.487 1.415 2.407 3.37 2.407 5.53 0 4.705-5.533 10.556-7.8 12.997a.577.577 0 01-.84-.005zm.421-16.79c-2.204 0-3.991 1.7-3.991 3.798 0 3.373 4.306 5.073 6.814 2.686 2.509-2.387.723-6.485-2.823-6.485zM14.25 7.89c-1.806-1.718-4.907-.495-4.907 1.935 0 2.429 3.101 3.652 4.907 1.934a2.643 2.643 0 000-3.869zM5.117 9.825c0 4.116 4.912 9.49 7.1 11.847 2.189-2.356 7.1-7.731 7.1-11.847 0-6-7.659-9.023-12.12-4.778-1.285 1.223-2.08 2.912-2.08 4.778z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );
}

export default LocationIcon;
