import React from "react";

function EmailIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <mask
                id="mask0_101_444"
                style={{ maskType: "alpha" }}
                width="25"
                height="25"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H25V25H0z"></path>
            </mask>
            <g mask="url(#mask0_101_444)">
                <path
                    fill="#93CDE9"
                    d="M3.015 18.916c.15 1.156.93 1.802 2.19 1.817h15.481c1.414 0 2.198-.785 2.214-2.21V7.218v-.015c-.015-.527-.119-.945-.32-1.285l-.015-.031a.515.515 0 00-.057-.088s0-.005-.005-.005c-.109-.16-.248-.3-.413-.403a.77.77 0 00-.15-.103 2.032 2.032 0 00-.532-.201 2.196 2.196 0 00-.743-.083H5.22c-1.033-.02-1.57.413-1.833.785-.258.36-.387.83-.387 1.435v11.3c0 .123 0 .252.015.392zm7.269-5.942l-6.252 4.533V7.218c0-.17.016-.32.042-.454l6.21 6.21zm10.407 6.727H5.22c-.635-.01-1.08-.17-1.177-.92v-.01l6.984-5.059.429.429.005.005c.63.614 1.31.826 2.013.63.005 0 .01-.005.015-.005.31-.098.63-.29.94-.568.026-.02.046-.047.067-.072.016-.016.031-.026.046-.042l.346-.346 6.974 5.033c-.077.661-.423.925-1.171.925zm1.14-12.89c.026.124.042.258.047.413v10.288l-6.241-4.502 6.194-6.2zM5.204 6.03h15.517c.145-.01.274.005.388.036l-6.567 6.567c-.025.02-.056.04-.077.067l-.63.63a.856.856 0 00-.14.134c-.185.16-.355.263-.515.32-.212.056-.542.077-1.002-.372l-.733-.733-6.618-6.623c.114-.015.238-.026.377-.026z"
                ></path>
            </g>
        </svg>
    );
}

export default EmailIcon;
