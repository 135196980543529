import React from "react";
import styles from "./mobileMenu/styles.module.scss";

export interface ILinks {
    headerTitle: string;
    footerTitle: string;
    url: string;
    showOnFooter: boolean;
}

export interface ILinksProps {
    isMobile?: boolean;
    isOpened?: boolean;
}

export const links: ILinks[] = [
    // {
    //     headerTitle: "Coverage Plans",
    //     footerTitle: "",
    //     url: "/coverage-plans",
    //     showOnFooter: false,
    // },
    // {
    //     headerTitle: "How It Works",
    //     footerTitle: "Auto Warranty Guides",
    //     url: "/how-it-works",
    //     showOnFooter: true,
    // },
    {
        headerTitle: "About Us",
        footerTitle: "Why AutoWarranty.com",
        url: "#why-auto-warranty-com",
        showOnFooter: true,
    },
    {
        headerTitle: "FAQ",
        footerTitle: "FAQ",
        url: "#faq",
        showOnFooter: true,
    },
];

const Links = ({ isMobile = false, isOpened = false }: ILinksProps) => {
    return (
        <div
            className={`${
                isMobile ? styles.mobileMenu : "flex items-center gap-12"
            } ${isOpened ? styles.open : ""}`}
        >
            {links.map((item) => (
                <a href={item.url} key={item.headerTitle}>
                    {item.headerTitle}
                </a>
            ))}
        </div>
    );
};

export default Links;
