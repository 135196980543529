import React from "react";

function PhoneIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <mask
                id="mask0_101_448"
                style={{ maskType: "alpha" }}
                width="25"
                height="25"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H25V25H0z"></path>
            </mask>
            <g mask="url(#mask0_101_448)">
                <path
                    fill="#93CDE9"
                    fillRule="evenodd"
                    d="M8.467 14.75c-1.192-2.025-2.588-4.97.34-6.257l.519-.228L8.13 5.02C3.264 6.72 4.887 12.445 6.805 15.69c1.928 3.26 6.156 7.496 10.087 4.21l-2.269-2.637-.46.326c-2.6 1.842-4.503-.816-5.695-2.84h-.001zm5.368-9.716c-.545-.127-.485-1.057.337-.855 4.98 1.229 7.918 6.294 6.45 11.13-.157.513-1.09.435-.86-.366 1.257-4.368-1.445-8.865-5.927-9.909zm-.696 2.567c-.547-.12-.5-1.058.33-.858a6.302 6.302 0 013.972 2.926 6.085 6.085 0 01.562 4.958c-.18.571-1.068.303-.886-.27a5.195 5.195 0 00-.478-4.233h.001c-.77-1.26-1.938-2.178-3.501-2.523zm-.685 2.571c-.559-.102-.536-1.063.324-.862a3.604 3.604 0 012.319 1.686 3.48 3.48 0 01.282 2.958c-.2.565-1.076.266-.876-.3a2.602 2.602 0 00-.208-2.203 2.691 2.691 0 00-1.841-1.279zm-4.438 1.096c.106.927.655 2.01 1.254 3.026h-.001c.946 1.545 2.382 3.953 4.352 2.558l.789-.559a.47.47 0 01.645.06l2.834 3.295a.448.448 0 01-.033.621c-4.47 4.213-9.476-.09-11.854-4.124C3.627 12.116 2.305 5.716 8.285 4.018a.465.465 0 01.565.283l1.487 4.035c.413 1.058-2.593.544-2.32 2.932z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );
}

export default PhoneIcon;
